import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyBW2iqPI7UnCvEn6uWM4-CodErosP-hi60',
  authDomain: 'davinci-ai-2bc20.firebaseapp.com',
  projectId: 'davinci-ai-2bc20',
  storageBucket: 'davinci-ai-2bc20.appspot.com',
  messagingSenderId: '14743927186',
  appId: '1:14743927186:web:d3433239772148a7b0694b',
  measurementId: 'G-71YWF2J4CH',
  databaseURL:
    'https://davinci-ai-2bc20-default-rtdb.europe-west1.firebasedatabase.app',
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);
const functions = getFunctions();
const auth = getAuth();
auth.languageCode = 'nb';

export { database, auth, functions };
