/* eslint-disable no-case-declarations */
const defaultSettings = {
  version: 1,
  user: false,
};

export const reducer = (settings, action) => {
  switch (action.type) {
    case 'update_settings':
      return {
        ...settings,
        [action.payload.key]: action.payload.value,
      };

    case 'update_user':
      return {
        ...settings,
        user: action.payload,
      };

    default:
      return settings;
  }
};

export const initialState = () => {
  return defaultSettings;
};
