import React from 'react';
import styles from './Frontpage.module.scss';

import Main from '../../components/Main/Main';
import Heading from '../../components/Heading/Heading';
import { Link } from 'react-router-dom';

const Frontpage = () => {
  return (
    <Main>
      <Heading>Alex' AI-Experiments</Heading>
      <nav className={styles.links}>
        <Link to={'/color'}>AI Color-picker</Link>
        <Link to={'/gradient'}>AI Gradient-picker (experimental)</Link>
        <Link to={'/analogy'}>The questionable analogy maker</Link>
        <Link to={'/harold'}>Harold the discontent chatbot</Link>
      </nav>
    </Main>
  );
};

export default Frontpage;
