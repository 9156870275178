import React, { useState, useEffect } from 'react';

import styles from './Main.module.scss';
import PropTypes from 'prop-types';

const Main = ({ children }) => {
  const [minHeight, setMinHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      setMinHeight(window.innerHeight);
    };
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <main className={styles.main} style={{ minHeight: `${minHeight}px` }}>
      {children}
    </main>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

Main.defaultProps = {
  children: null,
};

export default Main;
