import React, { useState, useEffect } from 'react';
import styles from './Color.module.scss';

import { functions } from '../../utils/firebase';
import { httpsCallable } from 'firebase/functions';
import { database } from '../../utils/firebase';
import { ref, onValue } from 'firebase/database';

import Main from '../../components/Main/Main';
import Heading from '../../components/Heading/Heading';

import classNames from 'classnames';

const Color = () => {
  const requestPrompt = httpsCallable(functions, 'requestPromptOld');

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [hex, setHex] = useState('');

  const [lastQuery, setLastQuery] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [changed, setChanged] = useState(true);

  const [inverted, setInverted] = useState(false);
  const [uniqueColors, setUniqueColors] = useState(0);

  useEffect(() => {
    const roundData = ref(database, `prompts/color`);
    onValue(roundData, (snapshot) => {
      const data = snapshot.val();
      const newUniqueColors = [...new Set(Object.values(data))].length;
      setUniqueColors(newUniqueColors);
    });
  }, []);

  useEffect(() => {
    if (
      currentQuery === lastQuery ||
      currentQuery === '' ||
      currentQuery.length < 3
    ) {
      setChanged(false);
    } else {
      setChanged(true);
    }
  }, [currentQuery, lastQuery]);

  useEffect(() => {
    if (result) {
      const hex = result.replace('#', '').replace(';', '').substring(0, 6);
      console.log(hex);
      var rgb = parseInt(hex, 16);

      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      if (luma < 70) {
        setInverted(true);
      } else {
        setInverted(false);
      }
      setHex(hex);
    }
  }, [result]);

  return (
    <Main>
      <div
        className={styles.background}
        style={{
          backgroundColor: `#${hex}`,
        }}
      />
      <form
        className={classNames(styles.content, {
          [styles.inverted]: inverted,
        })}
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          requestPrompt({
            type: 'color',
            input: [currentQuery],
          }).then((result) => {
            const data = result.data;
            setLastQuery(currentQuery);
            setCurrentQuery('');
            setLoading(false);
            if (data.type === 'success') {
              setResult(data.text);
            }
          });
        }}
      >
        {lastQuery ? (
          <Heading
            className={styles.query}
            size={3}
          >{`${lastQuery} #${hex.toLocaleUpperCase()}`}</Heading>
        ) : (
          <Heading size={3}>Hex AI</Heading>
        )}
        <input
          onChange={(e) => {
            setCurrentQuery(e.target.value);
          }}
          placeholder={'Describe a color'}
          type={'text'}
          value={currentQuery}
        />
        <button disabled={loading || !changed}>
          {loading ? 'Generating color...' : 'Generate color'}
        </button>
      </form>
      <div
        className={classNames(styles.count, {
          [styles.inverted]: inverted,
        })}
      >
        {uniqueColors.toLocaleString().replaceAll(',', '.')} /{' '}
        {(16777217).toLocaleString().replaceAll(',', '.')} colors found
      </div>
    </Main>
  );
};

export default Color;
