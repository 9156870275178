import styles from './Heading.module.scss';
import { H } from 'react-headings';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Heading = ({ className, size, children, ...props }) => {
  return (
    <H
      className={classNames(
        styles.heading,
        { [styles.default]: !className && size < 1 && size > 6 },
        { [styles.h1]: size === 1 },
        { [styles.h2]: size === 2 },
        { [styles.h3]: size === 3 },
        { [styles.h4]: size === 4 },
        { [styles.h5]: size === 5 },
        { [styles.h6]: size === 6 },
        className
      )}
      {...props}
    >
      {children}
    </H>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

Heading.defaultProps = {
  className: null,
  size: 0,
};

export default Heading;
