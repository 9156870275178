const names = [
  'Agnar',
  'Jacek',
  'Liliana',
  'Yonas',
  'Ilyas',
  'Imran',
  'Aneta',
  'Jan',
  'Marielle',
  'Stephan',
  'Nerijus',
  'Martin',
  'Herdis',
  'Kaare',
  'Ieva',
  'Norbert',
  'Marte',
  'Aiden',
  'Gunvald',
  'Toralf',
  'Ariana',
  'Remi',
  'Benedikte',
  'Nicole',
  'Gunnlaug',
  'Saga',
  'Mona',
  'Kurt',
  'Iryna',
  'Najma',
  'Nelly',
  'Sylwester',
  'Aase',
  'Adam',
  'Cindy',
  'Mattis',
  'Julianne',
  'Angelica',
  'Kristoffer',
  'Wilhelm',
  'Tomasz',
  'Leyla',
  'Skjalg',
  'Ingvil',
  'Henriette',
  'Abdul',
  'Aria',
  'George',
  'William',
  'Roman',
  'Iren',
  'Dariusz',
  'Gerhard',
  'Zahra',
  'Torbjørn',
  'Torfinn',
  'Cassandra',
  'Isaac',
  'Jeanette',
  'Filip',
  'Sandra',
  'Elzbieta',
  'Galina',
  'Annette',
  'Oddleif',
  'Brit',
  'Siham',
  'Sigfrid',
  'Dani',
  'Khalil',
  'Ruta',
  'Ulf',
  'Tatjana',
  'Jorid',
  'Aslak',
  'Axel',
  'Marius',
  'Suzanne',
  'Kirsten',
  'Grethe',
  'Zoran',
  'Vaidas',
  'Ragnhild',
  'Abdullahi',
  'Rebecka',
  'Kazimierz',
  'Tina',
  'Vilma',
  'Thomas',
  'Oddvar',
  'Marc',
  'Evelina',
  'Erica',
  'Olga',
  'Gretha',
  'Lucas',
  'Kristine',
  'Raymond',
  'Amin',
  'Borgar',
  'Torun',
  'Agnes',
  'Maia',
  'Leona',
  'Frøydis',
  'Sindre',
  'Jone',
  'Harriet',
  'Henryk',
  'Mai-Britt',
  'Gyda',
  'Marzena',
  'Une',
  'Sahra',
  'Normann',
  'Magnhild',
  'Dominika',
  'Tora',
  'Evy',
  'Ivana',
  'Ingun',
  'Caroline',
  'Mathilde',
  'Jorge',
  'Kaia',
  'Edna',
  'Bernhard',
  'Mons',
  'Natasha',
  'Radoslaw',
  'Frank',
  'Inga',
  'Norvald',
  'Olivier',
  'Jo',
  'Ernst',
  'Turid',
  'Jon',
  'Arve',
  'Rafal',
  'Jonn',
  'Ramunas',
  'Jennifer',
  'Gisle',
  'Egil',
  'Emily',
  'Stanislaw',
  'Pelle',
  'Merethe',
  'Håkon',
  'Magne',
  'Hamza',
  'Gine',
  'Iwona',
  'Lasse',
  'Kim',
  'Elea',
  'Arnstein',
  'Leonardo',
  'Mette',
  'Alicja',
  'Selam',
  'Per-Arne',
  'Janita',
  'Darius',
  'Teo',
  'Martynas',
  'Gediminas',
  'Oddmund',
  'Nour',
  'Eilin',
  'Aasta',
  'Mateusz',
  'Erika',
  'Mariam',
  'Lin',
  'Elsa',
  'Rahma',
  'Abraham',
  'Svend',
  'Marcin',
  'Carla',
  'Yngve',
  'Gøril',
  'Nova',
  'Krzysztof',
  'Jahn',
  'Gunnar',
  'Roland',
  'Miriam',
  'Katrine',
  'Dag',
  'Bjarte',
  'Elsie',
  'Mathea',
  'Aylin',
  'Stephen',
  'Oliwia',
  'Vivian',
  'Torbjørg',
  'Nikola',
  'Usman',
  'Samuel',
  'Eli',
  'Brian',
  'Gina',
  'Sienna',
  'Dainius',
  'Mary-Ann',
  'Trine',
  'Ailo',
  'Omer',
  'Kristian',
  'Elisa',
  'Renee',
  'Mike',
  'Rasmus',
  'Kjersti',
  'Rahel',
  'Miroslav',
  'Hala',
  'Oksana',
  'Siw',
  'Roald',
  'Ane',
  'Sylvia',
  'Juni',
  'Mina',
  'Edita',
  'Emma',
  'Yosef',
  'Joan',
  'Maiken',
  'Joao',
  'Dominik',
  'Sanja',
  'Muhammed',
  'Selmer',
  'Joel',
  'Liva',
  'Øystein',
  'Eden',
  'Sonia',
  'Øistein',
  'Melvin',
  'Åse',
  'Alfred',
  'Yvonne',
  'Remy',
  'Frid',
  'Anna',
  'Betina',
  'Hedda',
  'Manuel',
  'Alan',
  'Kate',
  'Sophie',
  'Kinga',
  'Lana',
  'Mira',
  'Åsne',
  'Violeta',
  'Adrian',
  'Khaled',
  'Andrew',
  'Elna',
  'Ronny',
  'Nataniel',
  'Gro',
  'Erland',
  'Mirjam',
  'Aida',
  'Heidi',
  'My',
  'Malena',
  'Jenni',
  'Carina',
  'Erna',
  'Jamila',
  'Cornelius',
  'Lene',
  'Katrin',
  'Samira',
  'Charlie',
  'Cato',
  'Aage',
  'Susanne',
  'Agathe',
  'Mahamed',
  'Emmeline',
  'Marvin',
  'Ulrik',
  'Isak',
  'Szymon',
  'Sahar',
  'Tara',
  'Nils',
  'Ranveig',
  'Kristen',
  'Matias',
  'Valentin',
  'Wiktoria',
  'Birgit',
  'Sigurd',
  'Nikolay',
  'Sylwia',
  'Fillip',
  'Nicoline',
  'Sol',
  'Rita',
  'Nicholas',
  'Leon',
  'Eirill',
  'Marlen',
  'Ewelina',
  'Sabine',
  'Lilja',
  'Rune',
  'Mathias',
  'Alexandru',
  'Bilal',
  'Helge',
  'Hubert',
  'Venche',
  'Melanie',
  'Emilian',
  'Rachel',
  'Hamid',
  'Ann-Mari',
  'Solveig',
  'Nancy',
  'Alf',
  'Eilif',
  'Carmen',
  'Lucy',
  'Jennie',
  'Angelina',
  'Regina',
  'Holger',
  'Desiree',
  'Børge',
  'Idris',
  'Gloria',
  'Ali',
  'Dorthe',
  'Elliot',
  'Lukasz',
  'Tormod',
  'Rene',
  'Jonatan',
  'Ben',
  'Anne-Grethe',
  'Emilija',
  'Klaus',
  'Senait',
  'Vår',
  'Alvilde',
  'Anita',
  'Tarald',
  'Kestutis',
  'Sidsel',
  'Edmund',
  'Iver',
  'Ari',
  'Carolina',
  'Yousef',
  'Vibecke',
  'Ingvild',
  'Helin',
  'Olava',
  'Silje',
  'Asbjørn',
  'Julius',
  'Arnt',
  'Yahya',
  'Ewa',
  'Milian',
  'Emina',
  'Ravn',
  'Mads',
  'Theresa',
  'Mikal',
  'Birgitte',
  'Paulius',
  'Ingebrigt',
  'Kolbjørn',
  'Eiril',
  'Idun',
  'Christian',
  'Mildrid',
  'Bergljot',
  'Liv',
  'Lisbeth',
  'Mathilda',
  'Olve',
  'Amar',
  'Zoe',
  'Reidunn',
  'Bianca',
  'Renate',
  'Sigmund',
  'Wiggo',
  'Gard',
  'Thu',
  'Finn',
  'Malin',
  'Jamal',
  'Annelise',
  'Mantas',
  'Johan',
  'Gerda',
  'Kyrre',
  'Ronja',
  'Vanja',
  'Mariana',
  'Hans',
  'Thorstein',
  'Mateo',
  'Diana',
  'Tord',
  'Sheila',
  'Ann-Helen',
  'Lars',
  'Torben',
  'Arnfinn',
  'Vemund',
  'Nicklas',
  'Ingar',
  'Chris',
  'Tea',
  'Karin',
  'Martinus',
  'Ingerid',
  'Eirik',
  'Dan',
  'Felicia',
  'Alvin',
  'Ylva',
  'Julia',
  'Emmy',
  'Eivind',
  'Sondre',
  'Sølvi',
  'Robert',
  'Francisco',
  'Bettina',
  'Beathe',
  'Hanne',
  'Marie',
  'Birgitta',
  'Vibeke',
  'Aurimas',
  'Syver',
  'Asbjørg',
  'Halvard',
  'Mark',
  'Huda',
  'Naima',
  'Tristan',
  'Rolandas',
  'Lydia',
  'Johannes',
  'Niklas',
  'Una',
  'Eric',
  'Bengt',
  'Torild',
  'Haris',
  'Aagot',
  'Marek',
  'Tom',
  'Kine',
  'Malvin',
  'Vilhelm',
  'Dorota',
  'Kåre',
  'Halfdan',
  'Fiona',
  'Ahmet',
  'Børre',
  'Alicia',
  'Malika',
  'Ingfrid',
  'Reidun',
  'Aksel',
  'Jane',
  'Niclas',
  'Leif',
  'Reidar',
  'Synne',
  'Tuva',
  'Iben',
  'Leszek',
  'Matas',
  'Torkel',
  'Even',
  'Gunnbjørg',
  'Caspian',
  'Antonio',
  'Marina',
  'Melinda',
  'Ingjerd',
  'Alex',
  'Petra',
  'Ammar',
  'Else',
  'Olaf',
  'Regine',
  'Anne-Marie',
  'Hennie',
  'Jason',
  'Henning',
  'Dennis',
  'Ruben',
  'Kjartan',
  'Tanya',
  'Lauritz',
  'Felix',
  'Karim',
  'Truls',
  'Jeanett',
  'Lina',
  'Trude',
  'Lennart',
  'Matthias',
  'Ingolf',
  'Idunn',
  'Carsten',
  'Leander',
  'Elinor',
  'Tadeusz',
  'Melissa',
  'Julian',
  'Norah',
  'Kristel',
  'Krystian',
  'Jim',
  'Sigvald',
  'Ireneusz',
  'Ove',
  'Leni',
  'Igor',
  'Seline',
  'Henrikke',
  'Solomon',
  'Maximilian',
  'Fernando',
  'Ingri',
  'Judith',
  'Kirsti',
  'Mindaugas',
  'Carlos',
  'Leikny',
  'Stephanie',
  'Ausra',
  'Abdullah',
  'David',
  'Dagny',
  'Lily',
  'Eldrid',
  'Amal',
  'Salman',
  'Rudi',
  'Gorm',
  'Valeria',
  'Narve',
  'Susan',
  'Turi',
  'Haavard',
  'Minh',
  'Arturas',
  'Lars-Erik',
  'Wanja',
  'Peggy',
  'Salah',
  'Giedrius',
  'Mahmoud',
  'Lea',
  'Torunn',
  'Sebastian',
  'Sina',
  'Eirin',
  'Ingvald',
  'Margrete',
  'Kitty',
  'Morten',
  'Jonah',
  'Sturla',
  'Tomine',
  'Guttorm',
  'Agnete',
  'Anton',
  'Øyvind',
  'Renathe',
  'Nina',
  'Ivar',
  'Eystein',
  'Nikolas',
  'Claes',
  'Hussein',
  'Aryan',
  'Olivia',
  'Eskil',
  'Shirin',
  'Kato',
  'Alv',
  'Nadia',
  'Asta',
  'Leiv',
  'Juliane',
  'Arman',
  'Edward',
  'Sumaya',
  'Ema',
  'Noa',
  'Flemming',
  'Målfrid',
  'Oda',
  'Eliah',
  'Vegar',
  'Thea',
  'Tarjei',
  'Alma',
  'Erlend',
  'Jannike',
  'Ragne',
  'Farah',
  'Einar',
  'Amalie',
  'Salma',
  'Evelyn',
  'Widar',
  'Diego',
  'Hege',
  'Sam',
  'Wenke',
  'Rabia',
  'Bendik',
  'Anette',
  'Anne-Lise',
  'Magda',
  'Olena',
  'Anniken',
  'Frode',
  'Natalia',
  'Josefine',
  'Lilly',
  'Lisbet',
  'Malene',
  'Solfrid',
  'Ayse',
  'Tønnes',
  'Wigdis',
  'Reza',
  'Egle',
  'Hasan',
  'Siren',
  'Donatas',
  'Kathe',
  'Teodor',
  'Elias',
  'Martina',
  'Stella',
  'Ella',
  'Helene',
  'Møyfrid',
  'Kathrin',
  'Janicke',
  'Emilia',
  'Isabelle',
  'Rafael',
  'Brynjulf',
  'Paulina',
  'Dina',
  'Alfhild',
  'Rønnaug',
  'Thanh',
  'Selina',
  'Mehdi',
  'Khalid',
  'Jelena',
  'Kornelia',
  'Herborg',
  'Ingrida',
  'Bo',
  'Matheo',
  'Maj',
  'Zuzanna',
  'Christiane',
  'Embla',
  'Audny',
  'Kay',
  'Eileen',
  'Livia',
  'Tor-Erik',
  'Magny',
  'Jana',
  'Fredrikke',
  'Thuy',
  'Haldis',
  'Tatiana',
  'Antonia',
  'Tom-Erik',
  'Balder',
  'Nikoline',
  'Birte',
  'Jan-Erik',
  'Marian',
  'Jorun',
  'Birk',
  'Ole-Martin',
  'Svein',
  'Fride',
  'Milla',
  'Ottar',
  'Adelina',
  'Jonathan',
  'Karianne',
  'Osman',
  'Odin',
  'Sander',
  'Noah',
  'Asgeir',
  'Aram',
  'Tale',
  'Kjellfrid',
  'Muhammad',
  'Peder',
  'Victoria',
  'Kian',
  'Alina',
  'Emine',
  'Astri',
  'Torkil',
  'Johanna',
  'Izabela',
  'Jarand',
  'Daniel',
  'Gaute',
  'Sture',
  'Adnan',
  'Vladimir',
  'Hussain',
  'Liza',
  'Mille',
  'Yasin',
  'Adele',
  'Muna',
  'Kamal',
  'Steve',
  'Mila',
  'Bent',
  'Troy',
  'Safia',
  'Rino',
  'Jaroslaw',
  'Tomas',
  'Rut',
  'Georg',
  'Tor-Arne',
  'Yngvild',
  'Liam',
  'Ronald',
  'Elle',
  'Lidia',
  'Torkild',
  'Artur',
  'Lillian',
  'Emil',
  'Danuta',
  'Steffen',
  'Osvald',
  'Waldemar',
  'Rolv',
  'Ken',
  'Barbro',
  'Rebecca',
  'Henrik',
  'Maylen',
  'Anne-Kristin',
  'Madelen',
  'Jakob',
  'Joachim',
  'Sarah',
  'Milan',
  'Janne',
  'Vivi',
  'Leila',
  'Mary',
  'Jacob',
  'Nikola',
  'Eliza',
  'Nanna',
  'Evaldas',
  'Rose',
  'Martyna',
  'Brynjar',
  'Lukas',
  'Ivo',
  'Ingunn',
  'Grete',
  'Mari',
  'Liss',
  'Kaja',
  'Alette',
  'Thor',
  'Ayan',
  'Solbjørg',
  'Annlaug',
  'Hild',
  'Thorbjørn',
  'Noor',
  'Gun',
  'Sanne',
  'Helmer',
  'Veronika',
  'Dagfinn',
  'Eldbjørg',
  'Gabriela',
  'Johann',
  'Cesilie',
  'Ted',
  'Bjørn-Erik',
  'Sigrunn',
  'Yngvar',
  'Rania',
  'Linda',
  'Ask',
  'Charlotte',
  'Bruno',
  'Emilie',
  'Tobias',
  'Jasmin',
  'Hanna',
  'Edle',
  'Andrius',
  'Jannicke',
  'Mio',
  'Kamila',
  'Amalia',
  'Kim-Andre',
  'Savannah',
  'Abigail',
  'Lise',
  'Karol',
  'Trond',
  'Åslaug',
  'Mariann',
  'Gøran',
  'Hjørdis',
  'Esten',
  'Gunda',
  'Karsten',
  'Tim',
  'Jill',
  'Frøya',
  'Britt',
  'Vetle',
  'Antoni',
  'Kamil',
  'Borghild',
  'Carine',
  'Hulda',
  'Amelie',
  'Marthe',
  'Omid',
  'Arnulf',
  'Hedvig',
  'Ana',
  'Sami',
  'Iselin',
  'Erling',
  'Rigmor',
  'Tone',
  'Snorre',
  'Bjørge',
  'Richard',
  'Tadas',
  'Markus',
  'Youssef',
  'Irina',
  'Runa',
  'Amer',
  'Ailin',
  'Terje',
  'Trine-Lise',
  'Oddveig',
  'Jofrid',
  'Ahmed',
  'Joacim',
  'Bartlomiej',
  'Sigvart',
  'Irene',
  'Jørgen',
  'Hiba',
  'Helen',
  'Ivan',
  'Karen',
  'Wendy',
  'Ann',
  'Walid',
  'Alida',
  'Bertine',
  'Rebekka',
  'Alexander',
  'Elina',
  'Hjalmar',
  'Otto',
  'Arn',
  'Aisha',
  'Paul',
  'Ingebjørg',
  'Hatice',
  'Timothy',
  'Frits',
  'Halvor',
  'Kasper',
  'Herman',
  'Syed',
  'Vebjørn',
  'Slawomir',
  'Marthine',
  'Marwa',
  'Mohammed',
  'Norunn',
  'Janis',
  'Hugo',
  'Lovise',
  'Eyvind',
  'Theo',
  'Phillip',
  'Elvira',
  'Alice',
  'Jenny',
  'Matilda',
  'Alise',
  'Hilmar',
  'Gabriel',
  'Aina',
  'Thorleif',
  'Preben',
  'Stein',
  'Emilio',
  'Astrid',
  'Gunn',
  'Erle',
  'Edgar',
  'Baard',
  'Ingve',
  'Katarina',
  'Alisa',
  'Isabella',
  'Bernard',
  'Deividas',
  'Jasmine',
  'Jean',
  'Håvard',
  'Ayman',
  'Torill',
  'Njål',
  'Nathan',
  'Cathrine',
  'Thi',
  'Anthony',
  'Solvår',
  'Stian',
  'Magni',
  'Ludvig',
  'Margun',
  'Patrycja',
  'Ngoc',
  'Thanh',
  'Kristiane',
  'Karolina',
  'Magnar',
  'Vaida',
  'Celina',
  'Åshild',
  'Werner',
  'Per-Erik',
  'Bushra',
  'Anne-Lene',
  'Khadija',
  'Norma',
  'Ayla',
  'Anas',
  'Samir',
  'Petter',
  'Ragnar',
  'Haakon',
  'Elbjørg',
  'Elin',
  'Mohamad',
  'Bjørn',
  'Abdulkadir',
  'Sverre',
  'Synøve',
  'Nicolas',
  'Walter',
  'Maksymilian',
  'May',
  'Van',
  'Noel',
  'Rina',
  'Piotr',
  'Maria',
  'Grace',
  'Merete',
  'Jarl',
  'Colin',
  'Fred',
  'Samson',
  'Azra',
  'Ansgar',
  'Mahdi',
  'Mattias',
  'Bror',
  'Evald',
  'Ole-Christian',
  'Aleksandra',
  'Roger',
  'Saulius',
  'Inger',
  'Anni',
  'Signe',
  'Inger-Johanne',
  'Rayan',
  'Magdalena',
  'Hilde',
  'Oliver',
  'Ingeborg',
  'Hallvard',
  'Isabel',
  'Oscar',
  'Cecilie',
  'Joseph',
  'Sveinung',
  'Ragna',
  'Mikkel',
  'Doris',
  'Esther',
  'Lara',
  'Adelen',
  'Gabriele',
  'Max',
  'Miroslaw',
  'Clara',
  'Kari-Anne',
  'Anbjørg',
  'Vytautas',
  'Wieslaw',
  'Anja',
  'Lyder',
  'Sana',
  'Simone',
  'Leonard',
  'Beate',
  'May-Britt',
  'Steffan',
  'Inge',
  'Patrick',
  'Matthew',
  'Mario',
  'Terese',
  'Tilla',
  'Ada',
  'Emir',
  'Anny',
  'Brede',
  'Asha',
  'Isabell',
  'Unn',
  'Eilert',
  'Kerstin',
  'Inghild',
  'Emre',
  'Hassan',
  'Øivind',
  'Margit',
  'Elida',
  'Laila',
  'Aline',
  'Leah',
  'Sofija',
  'Ole-Jørgen',
  'Roar',
  'Tommy',
  'Aurora',
  'Luka',
  'Hannah',
  'Liz',
  'Edvin',
  'Kristin',
  'Torger',
  'Dalia',
  'Anne-Kari',
  'Andrea',
  'Matilde',
  'Rana',
  'Stig',
  'Mohamed',
  'Cecilia',
  'Olaug',
  'Edda',
  'Zara',
  'Gert',
  'Aud',
  'Olea',
  'Alexandra',
  'Natalie',
  'Viggo',
  'Julie',
  'Gudny',
  'Eir',
  'Martinius',
  'Anne-Grete',
  'Hamdi',
  'Emrik',
  'Anne-Karin',
  'Timian',
  'Kelly',
  'Malik',
  'Irmelin',
  'Kornelius',
  'Jomar',
  'Sofie',
  'Bjørnar',
  'Janniche',
  'Alva',
  'Abel',
  'Adil',
  'Silvia',
  'Wilma',
  'Elma',
  'Sadia',
  'Vera',
  'Ellen',
  'Kaj',
  'Judit',
  'Leo',
  'Vegard',
  'Annika',
  'Egidijus',
  'Sanna',
  'Ragni',
  'Zeinab',
  'Svanhild',
  'Torvald',
  'Christopher',
  'Svetlana',
  'Farhad',
  'Fadumo',
  'Sabrina',
  'Peter',
  'Borgny',
  'Milos',
  'Kjetil',
  'Anne-Mari',
  'Weronika',
  'Ann-Karin',
  'Siril',
  'Robertas',
  'Rainer',
  'Sven',
  'Teresa',
  'Susann',
  'Miranda',
  'Tor',
  'Freddy',
  'Kira',
  'Sidra',
  'Hans-Petter',
  'Heine',
  'Isa',
  'Kim',
  'Louise',
  'Ramona',
  'Elen',
  'Ulla',
  'Adel',
  'Hong',
  'John',
  'Kacper',
  'Hana',
  'Arvin',
  'Goran',
  'Ea',
  'Carl',
  'Elena',
  'Jonas',
  'Ragnvald',
  'Sophia',
  'Ina',
  'Boguslaw',
  'Dejan',
  'Bertha',
  'Nadja',
  'Gunnhild',
  'Justyna',
  'Gudrun',
  'Mia',
  'Levi',
  'Torhild',
  'Johnny',
  'Anne-Sofie',
  'Andreas',
  'Ørjan',
  'Veslemøy',
  'Hermann',
  'Solvor',
  'Leonora',
  'Haldor',
  'Joshua',
  'Liban',
  'Marcel',
  'Asma',
  'Camilla',
  'Philip',
  'Arnold',
  'Oddgeir',
  'Christoffer',
  'Gjertrud',
  'Elly',
  'Armin',
  'Vigdis',
  'Enya',
  'Anne-Britt',
  'Eigil',
  'Benthe',
  'Emelie',
  'Eleah',
  'Serina',
  'Aasmund',
  'Ida',
  'Fredrick',
  'Asmund',
  'Jette',
  'Ingvar',
  'Linea',
  'Danny',
  'Amund',
  'Tellef',
  'Tonje',
  'Ellie',
  'Mikail',
  'Glen',
  'Amir',
  'Marlene',
  'Louis',
  'Mariusz',
  'Jaran',
  'Silas',
  'Svein-Erik',
  'Severin',
  'Ester',
  'Nadine',
  'Frans',
  'Rakel',
  'Yusuf',
  'Omar',
  'Natan',
  'Mustafa',
  'Venke',
  'Agnieszka',
  'Nora',
  'Audun',
  'Fritz',
  'Mostafa',
  'Margarita',
  'Ola',
  'June',
  'Nikolai',
  'Eira',
  'Theodor',
  'Lorentz',
  'Tove',
  'Konrad',
  'Jørn',
  'Gabriella',
  'Viktor',
  'Oline',
  'Amanda',
  'Aileen',
  'Naomi',
  'Jorunn',
  'Gjermund',
  'Tilde',
  'Claus',
  'Unni',
  'Juliana',
  'Anine',
  'Gjert',
  'Ulrikke',
  'Simona',
  'Viktoria',
  'Simen',
  'Laura',
  'Molly',
  'Iris',
  'Audrius',
  'Alba',
  'Kristi',
  'Karla',
  'Dagrun',
  'Madeleine',
  'Annbjørg',
  'Ståle',
  'Birger',
  'Karolis',
  'Arna',
  'Frithjof',
  'Robin',
  'Milena',
  'Stina',
  'Katinka',
  'Sølve',
  'Anlaug',
  'Anne-Berit',
  'Silja',
  'Gintaras',
  'Linn',
  'Pernille',
  'Eline',
  'Veronica',
  'Andrzej',
  'Hilda',
  'Susanna',
  'Arian',
  'Oddbjørn',
  'Andrine',
  'Viktorija',
  'May-Liss',
  'Monica',
  'Mohammad',
  'Vida',
  'Bente',
  'Hodan',
  'Gudveig',
  'Dawid',
  'Elisabeth',
  'Sunniva',
  'Torgrim',
  'Amanuel',
  'Inger-Marie',
  'Mehmet',
  'Helle',
  'Isac',
  'Mali',
  'Edwin',
  'Oddvin',
  'Abdi',
  'Othilie',
  'Lena',
  'Bjørg',
  'Allan',
  'Margunn',
  'Bastian',
  'Janet',
  'Henry',
  'Juan',
  'Christine',
  'Pawel',
  'Wenche',
  'Ruth',
  'Sigrun',
  'Anders',
  'Victor',
  'Geir',
  'Agata',
  'Michael',
  'Oskar',
  'Krister',
  'Cornelia',
  'Vårin',
  'Pavel',
  'Kenneth',
  'Thora',
  'Casper',
  'Klara',
  'Håvar',
  'Jostein',
  'Aleksandar',
  'Siri',
  'Dominic',
  'Zakaria',
  'Sigrid',
  'Rasa',
  'Ingrid',
  'Boris',
  'Maryan',
  'Roy',
  'Edit',
  'Viljar',
  'Valborg',
  'Abdirahman',
  'Birthe',
  'Mie',
  'Ine',
  'Laurits',
  'Greta',
  'Solrun',
  'Andres',
  'Thorvald',
  'Tony',
  'Marco',
  'Amina',
  'Mahad',
  'Idar',
  'Jimmy',
  'Bozena',
  'Hildur',
  'Bella',
  'Claudia',
  'Maren',
  'Abdallah',
  'Jarle',
  'Kjellaug',
  'Celia',
  'Hallstein',
  'Bertil',
  'Gunvor',
  'Else-Marie',
  'Thore',
  'Barbara',
  'Eivor',
  'Lilian',
  'Kathrine',
  'Angela',
  'August',
  'Benjamin',
  'Per',
  'Maja',
  'Benny',
  'Iman',
  'Snefrid',
  'Malgorzata',
  'James',
  'Mathis',
  'Aleksander',
  'Viola',
  'Lill',
  'Mariell',
  'Zofia',
  'Sissel',
  'Tanja',
  'Frida',
  'Jacqueline',
  'Vilja',
  'Ingvill',
  'Jakub',
  'Otilie',
  'Roberto',
  'Karina',
  'Ågot',
  'Benedicte',
  'Fridtjof',
  'Katarzyna',
  'Rami',
  'Isa',
  'Sjur',
  'Filippa',
  'Knut',
  'Annabelle',
  'Åsmund',
  'Maya',
  'Solgunn',
  'Oddrun',
  'Eddie',
  'Monika',
  'Runar',
  'Aleksandr',
  'Guro',
  'Emely',
  'Fatima',
  'Marianne',
  'Anett',
  'Linus',
  'Kamilla',
  'Olav',
  'Lotta',
  'Wilmer',
  'Wojciech',
  'Jann',
  'Arvid',
  'Tage',
  'Ole-Kristian',
  'Iqra',
  'Patrik',
  'Espen',
  'Sigve',
  'Krystyna',
  'Ingelin',
  'Ilona',
  'Ferdinand',
  'Linnea',
  'Elizabeth',
  'Jurgita',
  'Torleiv',
  'Ryszard',
  'Ronnie',
  'Tiril',
  'Matheus',
  'Daiva',
  'Berit',
  'Saleh',
  'Adem',
  'Kjerstin',
  'Kent',
  'Melina',
  'Hildegunn',
  'Tyra',
  'Paal',
  'Aya',
  'Mikael',
  'Sofia',
  'Jasmina',
  'Halldis',
  'Phuong',
  'Simon',
  'Hadi',
  'Hogne',
  'Katja',
  'Are',
  'Anne-Marit',
  'Niels',
  'Kai',
  'Jessica',
  'Abdulrahman',
  'Glenn',
  'Lavrans',
  'Brita',
  'Kajsa',
  'Freya',
  'Jerzy',
  'Vilje',
  'Othelie',
  'Karoline',
  'Sigfred',
  'Katharina',
  'Ines',
  'Catherine',
  'Asle',
  'Sine',
  'Christer',
  'Jørund',
  'Rikke',
  'Didrik',
  'Harald',
  'Sonja',
  'Thelma',
  'Endre',
  'Dawit',
  'Trygve',
  'Bogdan',
  'Zainab',
  'Elisabet',
  'Margot',
  'Henny',
  'Vincent',
  'Lajla',
  'Sten',
  'Lisa',
  'Kathinka',
  'Jeppe',
  'Sigbjørn',
  'Irena',
  'Margaret',
  'Paula',
  'Liana',
  'Grzegorz',
  'Jens',
  'Aslaug',
  'Robel',
  'Christin',
  'Annar',
  'Jolanta',
  'Beatrice',
  'Josef',
  'Grazyna',
  'Dragan',
  'Tirill',
  'Michaela',
  'Lindis',
  'Christen',
  'Jardar',
  'Arkadiusz',
  'Steven',
  'Johanne',
  'Sabina',
  'Said',
  'Catrine',
  'Franciszek',
  'Willy',
  'Therese',
  'Eilen',
  'Amy',
  'Denis',
  'Alejandro',
  'Belinda',
  'Edel',
  'Wiktor',
  'Matteo',
  'Medina',
  'Aashild',
  'Madelene',
  'Gintare',
  'Marta',
  'Villy',
  'Odd',
  'Anne-Mette',
  'Ricardo',
  'Arild',
  'Brith',
  'Saima',
  'Daniella',
  'Cristian',
  'Marian',
  'Amelia',
  'Hanan',
  'Eva',
  'Nellie',
  'Luna',
  'Oddny',
  'Sivert',
  'Elise',
  'Ahmad',
  'Magnus',
  'Maciej',
  'Halima',
  'Helga',
  'Pia',
  'Bodil',
  'Hermine',
  'Marion',
  'Thale',
  'Torjus',
  'Ellinor',
  'Torgeir',
  'Catharina',
  'Rolf',
  'Line',
  'Siv',
  'Klaudia',
  'Mari-Ann',
  'Hafsa',
  'Torje',
  'Elling',
  'Marcus',
  'Ian',
  'Deborah',
  'Kennet',
  'Aleksandrs',
  'Storm',
  'Cristina',
  'Harry',
  'Nada',
  'Albert',
  'Miguel',
  'Betty',
  'Amira',
  'Lone',
  'Torstein',
  'Renata',
  'Rosa',
  'Gry',
  'Gudmund',
  'Eliana',
  'Synnøve',
  'Torleif',
  'Pål',
  'Justin',
  'Kjell',
  'Helena',
  'Kjellrun',
  'Ole-Petter',
  'Hauk',
  'Oddbjørg',
  'Arunas',
  'Cicilie',
  'Dagmar',
  'Stefan',
  'Tamara',
  'Atle',
  'Loke',
  'Edyta',
  'Jørg',
  'Agne',
  'Constantin',
  'Gitte',
  'Marija',
  'Karine',
  'Marko',
  'Ludvik',
  'Saba',
  'Ma',
  'Scott',
  'Åge',
  'Vanessa',
  'Kevin',
  'Andrej',
  'Anisa',
  'Michal',
  'Karstein',
  'Randi',
  'Marita',
  'Mika',
  'Faisal',
  'Jozef',
  'Fabian',
  'Patryk',
  'Ava',
  'Celine',
  'Francis',
  'Åsta',
  'Trym',
  'Marit',
  'Tine',
  'Arya',
  'Lykke',
  'Connie',
  'Jonny',
  'Signy',
  'Annie',
  'Anneli',
  'Christina',
  'Eldar',
  'Morgan',
  'Maryam',
  'Ketil',
  'Søren',
  'Erik',
  'Olai',
  'Zbigniew',
  'Ismail',
  'Arne',
  'Alvhild',
  'Patricia',
  'Przemyslaw',
  'Mats',
  'Christel',
  'Anastasia',
  'Pauline',
  'Serine',
  'Toril',
  'Nathaniel',
  'Live',
  'Arvydas',
  'Lotte',
  'Mikolaj',
  'Jesper',
  'Jose',
  'Samantha',
  'Selma',
  'Charles',
  'Hermod',
  'Joanna',
  'Tonny',
  'Urszula',
  'Janusz',
  'Dorthea',
  'Katherine',
  'Florian',
  'Ole',
  'Karl',
  'Edin',
  'Vidar',
  'Ibrahim',
  'Seyed',
  'Neo',
  'Kenny',
  'Lucia',
  'Margareth',
  'Tore',
  'Åsa',
  'Jack',
  'Katrina',
  'Amna',
  'Lilli',
  'Sean',
  'Arthur',
  'Conrad',
  'Emmanuel',
  'Frederik',
  'Guri',
  'Torodd',
  'Edvard',
  'Audhild',
  'Arnhild',
  'Elfrid',
  'Emanuel',
  'Linas',
  'Pedro',
  'Ekaterina',
  'Rudolf',
  'Ørnulf',
  'Rannveig',
  'Ingmar',
  'Damian',
  'Anwar',
  'Ryan',
  'Mariel',
  'Oddlaug',
  'Fanny',
  'Larisa',
  'Gunhild',
  'Gabrielle',
  'Gustav',
  'Brage',
  'Jøran',
  'Ariel',
  'Michelle',
  'Ebba',
  'Steinar',
  'Fredrik',
  'Aron',
  'Andris',
  'Sylvi',
  'Nicolai',
  'Andre',
  'Gunnvor',
  'Gudbrand',
  'Mai',
  'Irma',
  'Imre',
  'Adriana',
  'Nathalie',
  'Margrethe',
  'Marry',
  'Gerd',
  'Anne',
  'Tron',
  'Martine',
  'Svenn',
  'Daria',
  'Esben',
  'Falk',
  'Luis',
  'Andrei',
  'Tordis',
  'Abbas',
  'Ann-Kristin',
  'Ann-Christin',
  'Edith',
  'Emmeli',
  'Ninni',
  'Daniela',
  'Dana',
  'Georgios',
  'Magna',
  'Bartosz',
  'Kaisa',
  'Bård',
  'Valentina',
  'Sara',
  'Yasmin',
  'Sharon',
  'Maud',
  'Toni',
  'Bernt',
  'Eskild',
  'Thilde',
  'Josephine',
  'Luca',
  'Malak',
  'Kristina',
  'Brynhild',
  'Kari',
  'Cathrin',
  'Varg',
  'Bjarne',
  'Vilde',
  'Angelika',
  'Rikard',
  'Tia',
  'Agnethe',
  'Hallgeir',
  'Inger-Lise',
  'Joakim',
  'Nicolay',
  'Martha',
  'Fatemeh',
  'Fatma',
  'Aaron',
  'Beata',
  'Stine',
  'Torgunn',
  'Joar',
  'Ådne',
];

export default names;
