import React, { useState, useEffect } from 'react';
import styles from './Gradient.module.scss';

import { functions } from '../../utils/firebase';
import { httpsCallable } from 'firebase/functions';

import Main from '../../components/Main/Main';
import Heading from '../../components/Heading/Heading';

import classNames from 'classnames';

const Gradient = () => {
  const requestPrompt = httpsCallable(functions, 'requestPromptOld');

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');

  const [lastQuery, setLastQuery] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [changed, setChanged] = useState(true);

  const [inverted, setInverted] = useState(false);

  useEffect(() => {
    if (
      currentQuery === lastQuery ||
      currentQuery === '' ||
      currentQuery.length < 3
    ) {
      setChanged(false);
    } else {
      setChanged(true);
    }
  }, [currentQuery, lastQuery]);

  return (
    <Main>
      <div
        className={styles.background}
        style={{
          background: `${result.substring(0, result.indexOf(')'))}`,
        }}
      />
      <form
        className={classNames(styles.content, {
          [styles.inverted]: inverted,
        })}
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          requestPrompt({
            type: 'gradient',
            input: [currentQuery],
          }).then((result) => {
            const data = result.data;
            console.log(data);
            setLastQuery(currentQuery);
            setCurrentQuery('');
            setLoading(false);
            if (data.type === 'success') {
              setResult(data.text);
            }
          });
        }}
      >
        {lastQuery ? (
          <Heading className={styles.query} size={3}>{`${lastQuery}`}</Heading>
        ) : (
          <Heading size={3}>Gradient AI</Heading>
        )}
        <input
          onChange={(e) => {
            setCurrentQuery(e.target.value);
          }}
          placeholder={'Describe a gradient'}
          type={'text'}
          value={currentQuery}
        />
        <button disabled={loading || !changed}>
          {loading ? 'Generating gradient...' : 'Generate gradient'}
        </button>
      </form>
    </Main>
  );
};

export default Gradient;
