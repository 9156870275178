import React from "react";
import App from "./App";
import ReactDOM from "react-dom/client";

import { SettingsProvider } from "./contexts/settings-provider/index";
import "./styles/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </React.StrictMode>
);
