import React from 'react';
import styles from './Button.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Button = ({
  link,
  href,
  icon,
  frontIcon,
  rearIcon,
  inverted,
  ghost,
  large,
  className,
  children,
  ...props
}) => {
  const style = classNames(
    styles.button,
    { [styles.inverted]: inverted },
    { [styles.ghost]: ghost },
    { [styles.large]: large },
    { [styles.onlyIcon]: icon },
    className
  );

  const content = icon ? (
    <span className={styles.content}>
      <span className={styles.icon}>{icon()}</span>
    </span>
  ) : (
    <span className={styles.content}>
      {frontIcon && <span className={styles.sideIcon}>{frontIcon()}</span>}
      {children && <span className={styles.text}>{children}</span>}
      {rearIcon && <span className={styles.sideIcon}>{rearIcon()}</span>}
    </span>
  );

  if (href) {
    return (
      <a
        className={style}
        {...props}
        href={href}
        target={'_blank'}
        rel={'noreferrer'}
      >
        {content}
      </a>
    );
  } else if (link) {
    return (
      <Link className={style} to={link} {...props}>
        {content}
      </Link>
    );
  } else {
    return (
      <button className={style} {...props}>
        {content}
      </button>
    );
  }
};

Button.propTypes = {
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  icon: PropTypes.func,
  frontIcon: PropTypes.func,
  rearIcon: PropTypes.func,
  ghost: PropTypes.bool,
  inverted: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  link: null,
  href: null,
  icon: null,
  frontIcon: null,
  rearIcon: null,
  ghost: false,
  inverted: false,
  large: false,
  className: null,
  children: null,
};

export default Button;
