import React, { useRef, useState, useEffect } from 'react';
import styles from './Marv.module.scss';

import { functions } from '../../utils/firebase';
import { httpsCallable } from 'firebase/functions';
import classNames from 'classnames';

import { MdVolumeUp, MdVolumeOff } from 'react-icons/md';

const Marv = () => {
  const startMessage = 'What do you want?';

  const requestPrompt = httpsCallable(functions, 'requestPromptOld');

  const [loading, setLoading] = useState(false);

  const [lastQuery, setLastQuery] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [changed, setChanged] = useState(true);
  const [result, setResult] = useState(startMessage);
  const [mute, setMute] = useState(false);

  const conversationRef = useRef([]);
  const dialogueRef = useRef(null);

  useEffect(() => {
    const msg = new SpeechSynthesisUtterance();
    msg.text = result;
    if (!mute) {
      window.speechSynthesis.speak(msg);
    }
  }, [result, mute]);

  useEffect(() => {
    if (
      currentQuery === lastQuery ||
      currentQuery === '' ||
      currentQuery.length < 3
    ) {
      setChanged(false);
    } else {
      setChanged(true);
    }
  }, [currentQuery, lastQuery]);

  useEffect(() => {
    const container = dialogueRef.current;
    container.scrollTop = 1000000;
  }, [loading, currentQuery]);

  const dialgoue = conversationRef.current.map((bubble) => {
    return (
      <div
        key={bubble.text}
        className={classNames(
          styles.bubble,
          {
            [styles.question]: bubble.type === 'question',
          },
          {
            [styles.answer]: bubble.type === 'answer',
          }
        )}
      >
        {bubble.text}
      </div>
    );
  });

  return (
    <main className={styles.app}>
      <section className={styles.header}>
        Harold the discontent chat bot{' '}
        <button
          className={styles.mute}
          onClick={() => {
            setMute(!mute);
          }}
        >
          {mute ? <MdVolumeOff /> : <MdVolumeUp />}
        </button>
      </section>
      <section className={styles.dialogue} ref={dialogueRef}>
        <div className={styles.bubbles}>
          <div className={classNames(styles.bubble, styles.answer)}>
            {startMessage}
          </div>
          {dialgoue}
          {loading && (
            <div
              className={classNames(styles.bubble, styles.answer, styles.delay)}
            >
              <Loader />
            </div>
          )}
        </div>
      </section>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          setLastQuery(currentQuery);
          setCurrentQuery('');
          conversationRef.current.push({
            type: 'question',
            text: currentQuery,
          });
          requestPrompt({
            type: 'marv',
            input: [currentQuery],
          }).then((result) => {
            const data = result.data;
            if (data.type === 'success') {
              setResult(data.text);
              conversationRef.current.push({
                type: 'answer',
                text: data.text,
              });
            }
            setLoading(false);
          });
        }}
      >
        <input
          onChange={(e) => {
            setCurrentQuery(e.target.value);
          }}
          placeholder={'Write down your question for Harold'}
          type={'text'}
          value={currentQuery}
        />
        <button disabled={loading || !changed}>
          {loading ? 'Asking Harold...' : 'Ask Harold'}
        </button>
      </form>
    </main>
  );
};

const Loader = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.ball} />
      <div className={styles.ball} />
      <div className={styles.ball} />
    </div>
  );
};

export default Marv;
