import React, { useState, useEffect, useContext, useCallback } from 'react';
import styles from './Analogy.module.scss';

import { SettingsContext } from '../../contexts/settings-provider';
import { database, functions } from '../../utils/firebase';
import { ref, onValue, off, set } from 'firebase/database';
import { httpsCallable } from 'firebase/functions';

import { MdVolumeUp, MdVolumeOff, MdThumbUp } from 'react-icons/md';
import classNames from 'classnames';
import options from '../../data/options';
import checkIfToday from '../../utils/checkIfToday';
import logo from '../../assets/logo.svg';

const dailyTickets = 20;
const autoGenerate = true;

const Analogy = () => {
  const [settings] = useContext(SettingsContext);

  const requestPrompt = httpsCallable(functions, 'requestPrompt');

  const [loading, setLoading] = useState(autoGenerate);
  const [mute, setMute] = useState(true);

  const [lastQuery, setLastQuery] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [changed, setChanged] = useState(true);
  const [invalid, setInvalid] = useState(true);
  const [generated, setGenerated] = useState(false);

  const [redacted, setRedated] = useState(false);
  const [path, setPath] = useState('');
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);
  const [result, setResult] = useState('');
  const [image, setImage] = useState('');
  const [url, setUrl] = useState('');

  const [tickets, setTickets] = useState(dailyTickets);

  const [object1, setObject1] = useState('');
  const [object2, setObject2] = useState('');

  useEffect(() => {
    const userRef = ref(database, `users/${settings.user.uid}/tickets`);
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        if (checkIfToday(data.time)) {
          setTickets(data.balance);
        } else {
          setTickets(dailyTickets);
        }
      }
    });
    return () => {
      off(userRef);
    };
  }, [settings.user]);

  const generateRandomInputs = useCallback(() => {
    const getRandomOption = () => {
      return options[Math.round((options.length - 1) * Math.random())];
    };
    setObject1(getRandomOption());
    setObject2(getRandomOption());
  }, []);

  useEffect(() => {
    const msg = new SpeechSynthesisUtterance();
    msg.pitch = 2 * Math.random();
    msg.rate = Math.max(1 * Math.random(), 0.5);
    msg.text = result;
    if (!mute) {
      window.speechSynthesis.speak(msg);
    }
  }, [result, mute]);

  useEffect(() => {
    setCurrentQuery(`${object1}-${object2}`);
    if (object1.length < 3 || object2.length < 3) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [object1, object2]);

  useEffect(() => {
    if (currentQuery === lastQuery) {
      setChanged(false);
    } else {
      setChanged(true);
    }
  }, [currentQuery, lastQuery]);

  const getAnalogy = useCallback(() => {
    setLastQuery(`${object1}-${object2}`);
    setGenerated(true);
    setLoading(true);
    setLikes(0);
    setLiked(false);
    setRedated(false);
    setResult('');
    setImage('');
    setUrl('');

    requestPrompt({
      type: 'analogy',
      input: [object1, object2],
      media: true,
    }).then((analogy) => {
      if (analogy.data.type === 'success') {
        setPath(analogy.data.text);
      } else if (analogy.data.type === 'error') {
        setPath(null);
        setLoading(false);
      }
    });
  }, [object1, object2, requestPrompt]);

  useEffect(() => {
    let dataRef;
    if (path) {
      dataRef = ref(database, `prompts/${path.type}/${path.key}`);
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        if (data.text) {
          setLoading(false);
          setResult(data.text);
          setLikes(data.likes);
          if (data.text === '[Redacted]') {
            setRedated(true);
          }
          if (data.media) {
            setImage(data.media.src);
            setUrl(data.media.url);
          }
        }
      });
    }
    return () => {
      if (dataRef) {
        off(dataRef);
      }
    };
  }, [path]);

  const setLike = useCallback(
    (like) => {
      if (path) {
        const likeRef = ref(
          database,
          `users/${settings.user.uid}/likes/${path.type}/${path.key}`
        );
        set(likeRef, like);
      }
    },
    [path, settings.user]
  );

  useEffect(() => {
    let dataRef;
    if (path) {
      const likeRef = ref(
        database,
        `users/${settings.user.uid}/likes/${path.type}/${path.key}`
      );
      onValue(likeRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setLiked(true);
        } else {
          setLiked(false);
        }
      });
    }
    return () => {
      if (dataRef) {
        off(dataRef);
      }
    };
  }, [path, settings.user.uid]);

  useEffect(() => {
    if (autoGenerate) {
      generateRandomInputs();
    }
  }, [generateRandomInputs]);

  useEffect(() => {
    if (autoGenerate) {
      if (!generated && !invalid) {
        getAnalogy();
      }
    }
  }, [generated, invalid, getAnalogy]);

  useEffect(() => {
    if (liked) {
      setLikes(Math.max(likes + 1, 0));
    } else {
      setLikes(Math.max(likes - 1, 0));
    }
  }, [liked]);

  const ready = !loading && result;

  return (
    <main className={styles.app}>
      {tickets <= 0 ? (
        <section className={styles.content}>
          <div className={styles.intro}>
            <p>
              You are out of generations for today, come back again for more
              tomorrow
            </p>
          </div>
        </section>
      ) : (
        <section className={styles.content}>
          {!generated && (
            <div className={styles.intro}>
              <p>
                Fill in the form below and click "Generate" to generate a new
                analogy
              </p>
            </div>
          )}
          {loading && (
            <div className={styles.loading}>
              <p>Generating analogy...</p>
            </div>
          )}
          {ready && (
            <div className={styles.result}>
              <video
                loop
                muted
                autoPlay
                className={styles.video}
                src={
                  redacted
                    ? 'https://media0.giphy.com/media/3o7TKH1GmW1Uj7ugBG/giphy-loop.mp4?cid=d8a158bdvqatvez6m2uem4ia5abt23jxsclmvcohismje4pl&amp;rid=giphy-loop.mp4&amp;ct=g'
                    : image
                }
              />
              {redacted ? (
                <p className={styles.redacted}>
                  This result has been redacted by our AI filter
                </p>
              ) : (
                <p className={styles.quote}>{result}</p>
              )}
              {!redacted && (
                <div className={styles.actions}>
                  <a
                    href={`https://twitter.com/intent/tweet?text=${`Analogybot.wtf says: "${result}"`}&url=${url}`}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    <button>Tweet this analogy</button>
                  </a>
                  <button
                    className={classNames({
                      [styles.secondary]: !liked,
                    })}
                    onClick={() => {
                      setLike(!liked);
                    }}
                  >
                    <MdThumbUp />
                    {likes}
                  </button>
                </div>
              )}
            </div>
          )}
        </section>
      )}
      <section
        className={classNames(styles.prompt, {
          [styles.hidden]: loading || tickets <= 0,
        })}
      >
        <div className={styles.input}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!loading && !invalid && changed) {
                getAnalogy();
              }
            }}
          >
            <input
              placeholder={'object 1'}
              value={object1}
              onChange={(e) => {
                if (!loading) {
                  const value = e.target.value;
                  setObject1(value.substring(0, 14));
                }
              }}
            />
            is like
            <input
              placeholder={'object 2'}
              value={object2}
              onChange={(e) => {
                if (!loading) {
                  const value = e.target.value;
                  setObject2(value.substring(0, 14));
                }
              }}
            />
            in that...
            <button hidden>generate</button>
          </form>
        </div>
        <div className={styles.actions}>
          <button
            disabled={loading}
            onClick={generateRandomInputs}
            className={styles.secondary}
          >
            Randomize
          </button>
          <button
            disabled={!changed || loading || invalid}
            onClick={getAnalogy}
          >
            Generate analogy
          </button>
        </div>
        <div className={styles.disclaimer}>
          A tech experiment by Oslo-based technology and design studio{' '}
          <a href="https://try.no">TRY Creative Tech</a>. This is a direct and
          unfiltered interaction between you and the DaVinci AI Text generation.
          TRY Creative Tech takes no responsibility for how you choose to use
          this tool. Code by{' '}
          <a href={'https://www.linkedin.com/in/alexander-solbakken/'}>Alex</a>{' '}
          @TRY.
          {/**/}
        </div>
      </section>
      {/*
      <div className={styles.tickets}>Daily tickets left: {tickets}</div>
      */}
      <button
        onClick={() => {
          setMute(!mute);
        }}
        className={classNames(styles.mute, { [styles.muted]: mute })}
      >
        {mute ? <MdVolumeOff /> : <MdVolumeUp />}
      </button>
      <img
        className={styles.logo}
        src={logo}
        alt={'Analogybot.wtf'}
        onClick={() => {
          if (!autoGenerate) {
            setGenerated(false);
          }
        }}
      />
    </main>
  );
};

export default Analogy;
