import React, { useEffect, useContext, useState } from 'react';
import { SettingsContext } from './contexts/settings-provider';
import { auth } from './utils/firebase';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Analogy from './pages/Analogy/Analogy';
import Loading from './components/Loading/Loading';
import Color from './pages/Color/Color';
import Marv from './pages/Marv/Marv';
import Frontpage from './pages/Frontpage/Frontpage';
import Gradient from './pages/Gradient/Gradient';
import Name from './pages/Name/Name';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [, dispatch] = useContext(SettingsContext);

  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {})
      .catch((error) => {
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  }, []);

  useEffect(() => {
    const unsubsrcibe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoading(false);
        dispatch({ type: 'update_user', payload: user });
      }
    });
    return () => {
      unsubsrcibe();
    };
  }, [dispatch]);

  if (loading) {
    return <Loading>Authenticating</Loading>;
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Name />} />
          <Route path="/harold" element={<Marv />} />
          <Route path="/color" element={<Color />} />
          <Route path="/gradient" element={<Gradient />} />
          <Route path="/analogy" element={<Analogy />} />
          <Route path="/index" element={<Frontpage />} />
          <Route path="/analogy" element={<Analogy />} />
        </Routes>
      </BrowserRouter>
    );
  }
};

export default App;
