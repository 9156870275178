const checkIfToday = (timestamp) => {
  const last = new Date(timestamp);
  const now = new Date();
  if (
    last.getDate() === now.getDate() &&
    last.getMonth() === now.getMonth() &&
    last.getFullYear() === now.getFullYear()
  ) {
    return true;
  } else {
    return false;
  }
};

export default checkIfToday;
