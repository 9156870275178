import React from "react";
import styles from "./Loading.module.scss";
import PropTypes from "prop-types";

const Loading = ({ children }) => {
  return (
    <div className={styles.loading}>{children ? children : "Loading"}</div>
  );
};

Loading.propTypes = {
  children: PropTypes.node,
};

Loading.defaultProps = {
  children: null,
};

export default Loading;
